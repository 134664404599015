import { KeyboardArrowRightIcon } from "@enerbit/base";
import "../BillingPage.css";

interface DocumentDetailButtonProps {
  isDisabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const DocumentDetailButton = ({
  isDisabled,
  onClick,
}: DocumentDetailButtonProps) => {
  return (
    <button
      disabled={isDisabled}
      className="document-detail-button"
      onClick={onClick}
    >
      <KeyboardArrowRightIcon color="secondary" />
    </button>
  );
};

export default DocumentDetailButton;
