import { PrivateRoute, ThemeConfig } from "@enerbit/base";
import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import { InvoicesPayedPage } from "../pages/InvoicesPayed/InvoicesPayedPage";
import { InvoicesMicroProps } from "../root.component";
import { store } from "../store/store";
import { BillingPage } from "../pages/Billing/BillingPage";

export default function Routers({
  user_id,
  service_account_id,
  ess_id,
}: InvoicesMicroProps) {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <PrivateRoute>
          <HashRouter>
            <Routes>
              <Route
                path="*"
                element={
                  <BillingPage
                    user_id={user_id}
                    service_account_id={service_account_id}
                    ess_id={ess_id}
                  />
                }
              />
              <Route
                path="/invoices/:userId/detail"
                element={<InvoicesPayedPage />}
              />
            </Routes>
          </HashRouter>
        </PrivateRoute>
      </ThemeConfig>
    </Provider>
  );
}
