import {
  Box,
  DialogInformation,
  LoadingButton,
  handleRegistryMicrofront,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getDocumentsGrouped } from "../../features/actions/actions";
import { getUserById } from "../../features/actions/users/users.actions";
import {
  onCleanPayedGroups,
  onFiltersCleaned,
} from "../../features/invoice/invoice";
import { StateStorage } from "../../models/invoiceState";
import { AppDispatch } from "../../store/store";
import { CustomizedAccordion } from "../components/CustomizedAccordion";
import { DetailForm } from "../components/DetailForm";
import { FiltersInvoicePayed } from "./components/FiltersInvoicePayed";
import { HeaderInvoicesPayed } from "./components/HeaderInvoicesPayed";

export const InvoicesPayedPage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const {
    documentsGroupedPayed,
    isLoadingDocumentGroupedPayed,
    appUser,
    timesPayedFeched,
    filters,
  } = useSelector((state: StateStorage) => state.invoiceState);

  const dispatch = useDispatch<AppDispatch>();

  const { userId } = useParams();

  useEffect(() => {
    if (
      Boolean(documentsGroupedPayed.next_invoice_period) &&
      timesPayedFeched < 3 &&
      filters.month == null &&
      !filters.consecutive &&
      !filters.cufe &&
      !filters.serviceAccountId &&
      userId
    ) {
      dispatch(
        getDocumentsGrouped({
          invoice_period: moment(
            documentsGroupedPayed.next_invoice_period
          ).format("YYYY-MM"),
          is_paid: true,
          user_id: userId,
          reset_data: false,
        })
      );
    }
  }, [documentsGroupedPayed]);

  useEffect(() => {
    if (userId && Boolean(documentsGroupedPayed.next_invoice_period)) {
      dispatch(
        getDocumentsGrouped({
          invoice_period: moment(
            documentsGroupedPayed.next_invoice_period
          ).format("YYYY-MM"),
          is_paid: true,
          user_id: userId,
          reset_data: false,
        })
      );
    }

    return () => {
      dispatch(onCleanPayedGroups());
      dispatch(onFiltersCleaned());
    };
  }, []);

  useEffect(() => {
    if (!appUser && userId) {
      dispatch(getUserById(userId));
    }
  }, []);

  useEffect(() => {
    handleRegistryMicrofront({
      microName: "sidebar",
      customProps: { sidebarId: "single-spa-application:sidebar" },
      importName: "@enerBit/sidebar",
    });
  }, []);

  return (
    <>
      <section className="Container-root">
        <Box className="container-all-invoices">
          <HeaderInvoicesPayed />
          <Box className="content-all-invoices">
            <FiltersInvoicePayed />
            <Box className="payed-invoices-box">
              {documentsGroupedPayed.document_groups.map((documentGroup) => (
                <CustomizedAccordion
                  key={uuidv4()}
                  openDialogInformation={() => {
                    setIsDrawerOpen(true);
                  }}
                  documentGrouped={documentGroup}
                  canCheck={false}
                />
              ))}
            </Box>
            {Boolean(documentsGroupedPayed.next_invoice_period) && (
              <LoadingButton
                variant="outlined"
                loading={isLoadingDocumentGroupedPayed}
                disabled={isLoadingDocumentGroupedPayed}
                onClick={() => {
                  if (userId) {
                    dispatch(
                      getDocumentsGrouped({
                        invoice_period: moment(
                          documentsGroupedPayed.next_invoice_period
                        ).format("YYYY-MM"),
                        is_paid: true,
                        user_id: userId,
                        reset_data: false,
                      })
                    );
                  }
                }}
              >
                Ver más
              </LoadingButton>
            )}
          </Box>
        </Box>
      </section>
      <DialogInformation
        onCloseAction={() => {}}
        content={<DetailForm />}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </>
  );
};
