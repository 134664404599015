import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  enerbitColors,
  formatterPeso,
  Grid,
  Typography,
} from "@enerbit/base";
import {
  HeadTableInvoice,
  TableInvoice,
} from "./components/TableInvoiceInformation";
import { AppDispatch } from "../../store/store";
import { useEffect, useState } from "react";
import {
  getTotalDue,
  getTotalOwedByEssId,
  getUserBillingStatus,
} from "../../store/actions/billing.actions";
import ServiceGroups from "./components/ServiceGroups/ServiceGroupsSection";
import {
  serviceAccountSelect,
  setDefaultServicesAccountData,
  setServicesAccountSelect,
} from "../../store/slices/serviceAccountSlice";
import { InvoicesMicroProps } from "../../root.component";
import { StateStorage } from "../../models/invoiceState";
import { ApplyPaymentFormDialog } from "../SectionInvoices/components/ApplyPaymentFormDialog";
import { ResumeDialog } from "../SectionInvoices/components/ResumeDialog";
import { BillingStatus } from "../../models/enums/BillingStatus";
import {
  clearBillingStatus,
  setEssId,
  setUserId,
} from "../../store/slices/billingSlice";

export const BillingPage = ({
  user_id,
  service_account_id,
  ess_id,
}: InvoicesMicroProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [openResumeDialog, setOpenResumeDialog] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [pendingValue, setPendingValue] = useState(0);

  const [openApplyPaymentDialog, setOpenApplyPaymentDialog] = useState(false);
  const {
    document_period_start,
    document_period_end,
    selectedDocuments,
    essId,
    userId,
  } = useSelector((state: StateStorage) => state.billing);
  const serviceAccountId = useSelector(serviceAccountSelect);

  const handleCloseApplyPaymentDialog = () => {
    setOpenApplyPaymentDialog(false);
  };

  const handleCloseResumeDialog = () => {
    setOpenResumeDialog(false);
  };

  const handleResume = () => {
    setOpenResumeDialog(true);
  };

  useEffect(() => {
    dispatch(setServicesAccountSelect(""));
    dispatch(clearBillingStatus());
    dispatch(setDefaultServicesAccountData());
    dispatch(setEssId(ess_id));
    dispatch(setUserId(user_id));
    if (service_account_id) {
      dispatch(setServicesAccountSelect(service_account_id));
    }

    return () => {
      dispatch(setServicesAccountSelect(""));
    }
  }, []);

  useEffect(() => {
    if (userId == "") return;

    if (essId) {
      dispatch(getTotalOwedByEssId({ ess_id: ess_id!, month_filter: "" }));
    } else if (serviceAccountId) {
      dispatch(
        getTotalDue({ service_account_id: serviceAccountId, user_id: userId })
      );
    }

    if (essId || serviceAccountId) {
      dispatch(
        getUserBillingStatus({
          user_id: userId,
          document_components: null,
          document_period_start,
          document_period_end,
          document_state: null,
          service_account_ids: serviceAccountId ? [serviceAccountId] : [],
          page: 0,
          service_id: essId ?? null,
        })
      );
    }
  }, [serviceAccountId, essId, userId]);

  useEffect(() => {
    const documentsWithDue = Object.values(selectedDocuments)
      .map((doc) => {
        return doc.documents.filter((doc) =>
          [BillingStatus.Overdue, BillingStatus.Pending].includes(
            doc.billing_status
          )
        );
      })
      .flat();

    const totalPending = documentsWithDue.reduce(
      (prev, doc) => doc.payable_amount + prev,
      0
    );

    setShowResume(documentsWithDue.length > 0);
    setPendingValue(totalPending);
  }, [selectedDocuments]);

  return (
    <>
      <Box>
        <Box className="main-content" paddingBottom="76px">
          <Grid container sx={{ alignItems: "start" }}>
            {!ess_id && (
              <Grid item xs={3}>
                <ServiceGroups />
              </Grid>
            )}
            <Grid item xs={!ess_id ? 9 : 12} paddingLeft={4}>
              <HeadTableInvoice />
              <TableInvoice />
            </Grid>
            <ResumeDialog
              handleClose={handleCloseResumeDialog}
              open={openResumeDialog}
              onApplyPaymentClicked={() => {
                handleCloseResumeDialog();
                setOpenApplyPaymentDialog(true);
              }}
            />
            <ApplyPaymentFormDialog
              handleClose={handleCloseApplyPaymentDialog}
              open={openApplyPaymentDialog}
              userId={user_id}
            />
          </Grid>
          {showResume && (
            <Box
              className="sticky-bar animate__animated animate__slideInUp animate__faster"
              zIndex={1}
            >
              <Box className="group-text-sticky-bar">
                <Typography
                  variant="subtitle1"
                  color={enerbitColors.neutral[300]}
                >
                  Total grupo de pagos
                </Typography>
                <Typography
                  variant="h5"
                  color={enerbitColors.primary.main}
                  fontWeight="bold"
                >
                  {formatterPeso.format(pendingValue)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleResume}
                >
                  Ver resumen
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenApplyPaymentDialog(true)}
                >
                  Aplicar pago
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
