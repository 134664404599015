import { ServicesAccount } from "../../../../models/states/ServiceAccountState";
import { useSelector } from "react-redux";
import { serviceAccountSelect } from "../../../../store/slices/serviceAccountSlice";
import { getAliasServiceGroup } from "../../../../helpers/helperAccounts";
import { Box, enerbitColors } from "@enerbit/base";

interface ServiceAccountCardProps {
  serviceAccount: ServicesAccount;
  amountSelected: number;
  onClick: () => void;
}

const ServiceAccountCard = ({
  serviceAccount,
  onClick,
  amountSelected = 0,
}: ServiceAccountCardProps) => {
  const service = useSelector(serviceAccountSelect);

  return (
    <div
      onClick={onClick}
      className={`service-group-card ${
        serviceAccount.service_account_id === service ? "active" : ""
      }`}
    >
      <Box display="flex" justifyContent="space-between">
        <p className="service-group-title">
          {getAliasServiceGroup(serviceAccount)}
        </p>
        {amountSelected > 0 && (
          <div
            style={{
              backgroundColor: enerbitColors.primary[100],
              color: enerbitColors.primary[700],
            }}
            className="service-group-selected"
          >
            {amountSelected} Seleccionados
          </div>
        )}
      </Box>
      <p className="service-group-address">{serviceAccount.estate_address ?? ""}</p>
    </div>
  );
};

export default ServiceAccountCard;
