import React from "react";
import "../BillingPage.css";
import { CircularProgress } from "@enerbit/base";
import DownloadIcon from "./TableInvoiceInformation/DownloadIcon";

interface DownloadButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  loading: boolean;
}

const DownloadButton = ({ onClick, loading }: DownloadButtonProps) => {
  return (
    <button className="download-button" onClick={onClick}>
      {loading ? (
        <CircularProgress
          color="inherit"
          style={{ width: "10px", height: "10px" }}
        />
      ) : (
        <DownloadIcon />
      )}
      descargar
    </button>
  );
};

export default DownloadButton;
