import {
  Box,
  Card,
  CardContent,
  Grid,
  ThirdPartyServiceTypeNames,
  Typography,
  enerbitColors,
  formatterPeso,
  serviceNames,
  thirdPartyServiceNames,
} from "@enerbit/base";
import {
  mountedDisassembleAppsDetailMemos,
  mountedDisassembleAppsEssBillingDetail,
} from "../../../../helpers/spa/spa.helpers";
import { IDocument } from "../../../../models/interfaces/IBilling";
import DocumentDetailButton from "../DocumentDetailButton";
import { StateStorage } from "../../../../models/invoiceState";
import { useDispatch, useSelector } from "react-redux";
import { changeDownloadDocumentsIds } from "../../../../store/slices/billingSlice";
import { AppDispatch } from "../../../../store/store";

import "../../BillingPage.css";
import BillingStatusChip from "../BillingStatusChip";
import { serviceAccountSelect } from "../../../../store/slices/serviceAccountSlice";
import { format } from "date-fns";
import es from "date-fns/locale/es";

interface CardItemTableInvoiceProps {
  document: IDocument;
}

export const CardItemTableInvoice = ({
  document,
}: CardItemTableInvoiceProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const serviceAccountId = useSelector(serviceAccountSelect);
  const { selectedDocuments, essId } = useSelector(
    (state: StateStorage) => state.billing
  );

  const getDocumentTypeColor = (documentType: string): string => {
    const colors: { [key: string]: string } = {
      invoice: "",
      debit_note: enerbitColors.error[400] ?? "red",
      credit_note: enerbitColors.success[400] ?? "green",
    };

    return colors[documentType] ?? "";
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const isButtonDisabled = (document: IDocument): boolean => {
    return (
      (document.document_service_type ===
        ThirdPartyServiceTypeNames.billerbitSecurity ||
        document.document_service_type ===
          ThirdPartyServiceTypeNames.billerbitStreetLight ||
        document.document_service_type ===
          ThirdPartyServiceTypeNames.distributionInfrastructureFee) &&
      document.document_type !== "invoice"
    );
  };

  const onCheckDocumentsGroup = (
    document: IDocument,
    serviceAccountId: string
  ) => {
    dispatch(
      changeDownloadDocumentsIds({
        documents: [document],
        serviceAccountId: serviceAccountId,
      })
    );
  };

  const getDocumentId = (documentId: string): string => {
    const documentSplit = documentId.split("-");
    return documentSplit.length > 0 ? documentSplit[0] : documentId;
  };

  return (
    <>
      <Box marginLeft="1%">
        <Card
          variant="outlined"
          sx={{
            borderRadius: "13px",
            mb: "10px",
            borderColor: getDocumentTypeColor(document.document_type ?? ""),
          }}
        >
          <CardContent
            sx={{ display: "flex", padding: "10px 16px !important" }}
          >
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item xs={12} md={4}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <input
                    type="checkbox"
                    className="documents-checkbox"
                    checked={(
                      selectedDocuments[serviceAccountId]?.documents ?? []
                    ).some(
                      (doc) => doc.document_id == (document.document_id ?? "")
                    )}
                    style={{ marginRight: "10px" }}
                    onChange={() =>
                      onCheckDocumentsGroup(document, serviceAccountId)
                    }
                  />
                  <Box>
                    {serviceNames[document.document_service_type!] ??
                      thirdPartyServiceNames[document.document_service_type!] ??
                      document.document_service_type}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <BillingStatusChip type={document.billing_status} />
              </Grid>
              {document.document_due_at && (
                <Grid item xs={12} md={2}>
                  <Typography
                    fontWeight={500}
                    fontSize={16}
                    lineHeight={1}
                    sx={{ color: enerbitColors.neutral[700] }}
                  >
                    {format(
                      new Date(document.document_due_at),
                      "d 'de' MMMM yyyy",
                      { locale: es }
                    )}
                  </Typography>
                  <Typography
                    sx={{ color: enerbitColors.neutral[300] }}
                    lineHeight={1.3}
                  >
                    Fecha de vencimiento
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} md={2} display="flex" justifyContent="center">
                <div>
                  <Typography
                    fontWeight={500}
                    fontSize={17}
                    lineHeight={1}
                    sx={{ color: enerbitColors.neutral[700] }}
                  >
                    {getDocumentId(document.document_id ?? "")}
                  </Typography>
                  <Typography
                    sx={{ color: enerbitColors.neutral[300] }}
                    lineHeight={1.3}
                  >
                    Identificador
                  </Typography>
                </div>
                <div
                  onClick={() => copyToClipboard(document.document_id ?? "")}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    className="copy-icon"
                  >
                    <path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" />
                  </svg>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                display="flex"
                justifyContent="end"
                justifyItems="center"
              >
                <Typography
                  fontWeight={600}
                  fontSize={19}
                  display="inline-block"
                  sx={{ color: enerbitColors.neutral.main }}
                  marginRight={3}
                >
                  {formatterPeso.format(document.payable_amount!)}
                </Typography>
                <DocumentDetailButton
                  isDisabled={isButtonDisabled(document)}
                  onClick={() => {
                    if (document.document_type === "invoice") {
                      return mountedDisassembleAppsEssBillingDetail(
                        document.document_service_type!,
                        document.document_id!
                      );
                    } else if (document.document_type != "invoice") {
                      return mountedDisassembleAppsDetailMemos({
                        service_type_name: document.document_service_type!,
                        invoice_memo_id: document.document_id!,
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
