import { configureStore } from "@reduxjs/toolkit";
import invoiceReducer from "../features/invoice/invoice";
import billingReducer from "../store/slices/billingSlice";
import serviceAccountReducer from "./slices/serviceAccountSlice";

export const store = configureStore({
  reducer: {
    billing: billingReducer,
    invoiceState: invoiceReducer,
    serviceAccount: serviceAccountReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
