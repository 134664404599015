import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Skeleton,
  enerbitColors,
  formatterPeso,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../../models/invoiceState";
import "../../BillingPage.css";
import InvoiceFiltersCard from "../InvoiceFiltersCard";
import { useState } from "react";
import { AppDispatch } from "../../../../store/store";
import { downloadDocumentMerge } from "../../../../store/actions/billing.actions";
import { IDocument } from "../../../../models/interfaces/IBilling";
import DownloadIcon from "./DownloadIcon";

export const HeadTableInvoice = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    totalDue,
    isLoadingTotalOwed,
    filtersAmount,
    selectedDocuments,
    userId,
    essId,
    isDownloadingDocumentMerge,
  } = useSelector((state: StateStorage) => state.billing);

  const [showFiltersCard, setShowFiltersCard] = useState(false);

  const handleDownload = () => {
    const documentsToDownload = Object.entries(selectedDocuments);
    const documents: { [key: string]: IDocument[] } =
      documentsToDownload.reduce((acc, entry) => {
        return { ...acc, [entry[0]]: entry[1].documents };
      }, {});

    const serviceAccountData = documentsToDownload.map((entry) => {
      documents[entry[0]] = documents[entry[0]].filter((doc: IDocument) => {
        return !entry[1].invoicePeriods.includes(doc.document_period);
      });

      return {
        service_account_id: entry[0],
        invoice_periods: entry[1].invoicePeriods,
      };
    });

    dispatch(
      downloadDocumentMerge({
        service_accounts_data: serviceAccountData,
        userId,
        service_id: essId ?? undefined,
        document_ids: Object.values(documents)
          .flat()
          .map((doc) => {
            return {
              document_id: doc.document_id,
              document_type: doc.document_type,
            };
          }),
      })
    );
  };

  const documentsToDownload = Object.values(selectedDocuments).flat();

  return (
    <Grid container sx={{ alignItems: "center" }}>
      <Grid item xs={8}>
        <Typography
          color={enerbitColors.primary.main}
          sx={{ fontSize: "18px", fontWeight: "400" }}
        >
          {isLoadingTotalOwed ? (
            <Skeleton width={300} height={30} />
          ) : (
            <>
              <strong style={{ fontSize: "24px" }}>Facturas </strong>
              <span style={{ color: enerbitColors.primary[300] }}>
                (Deuda total: {formatterPeso.format(totalDue ?? 0)})
              </span>
            </>
          )}
        </Typography>
      </Grid>
      <Grid xs={4}>
        <Box position="relative" display="inline-block">
          <button
            className="filters-button"
            style={{
              borderColor: enerbitColors.primary.main,
              color: enerbitColors.primary.main,
              marginRight: "10px",
            }}
            onClick={() => setShowFiltersCard(!showFiltersCard)}
          >
            Filtros
            <span className="filters-button-counter">{filtersAmount}</span>
          </button>

          <Box
            position="absolute"
            display={showFiltersCard ? "inline-block" : "none"}
            top={45}
            right={10}
            zIndex={1}
          >
            <InvoiceFiltersCard
              onHideFiltersModal={() => setShowFiltersCard(false)}
            />
          </Box>
        </Box>
        <button
          className="filters-button"
          style={{
            backgroundColor: "var(--color-secondary500)",
            borderColor: "var(--color-secondary500)",
            color: "white",
          }}
          disabled={documentsToDownload.length == 0}
          onClick={handleDownload}
        >
          Descargar selección
          {isDownloadingDocumentMerge ? (
            <CircularProgress
              color="inherit"
              style={{ width: "10px", height: "10px", marginLeft: "8px" }}
            />
          ) : (
            <DownloadIcon />
          )}
        </button>
      </Grid>
    </Grid>
  );
};
