import { BillingStatus } from "../enums/BillingStatus";

export enum EBillingMemoStatus {
  InvoicesWithCreditNote = "INVOICES_WITH_CREDIT_NOTE",
  InvoicesWithDebitNote = "INVOICES_WITH_DEBIT_NOTE",
  InvoicesWithMemos = "INVOICES_WITH_MEMOS",
}

export enum EBillingInvoiceStatus {
  UpToDate = "UP_TO_DATE",
  ExpiredDocuments = "EXPIRED_DOCUMENTS",
  PendingDocuments = "PENDING_DOCUMENTS",
}

export interface IBillingStatusDetail {
  invoice_period: string;
  billing_status: EBillingInvoiceStatus;
  info_memos: EBillingMemoStatus;
  total: number;
}

export interface ITotalDue {
  total_due: number;
}

export interface IDocumentsGroupedResponse {
  invoice_period: string;
}

export interface IDocument {
  document_id: string;
  document_period: string;
  document_type: string;
  document_due_at?: string | null;
  document_issued_at: string;
  document_service_type: string;
  billing_status: BillingStatus;
  payable_amount: number;
  payment_date?: string | null;
  document_service_id: string;
}

export interface IDocumentsGroupedResponse {
  document_period: string;
  service_account_id: string;
  page: number;
  size: number;
  total: number;
  pages: number;
  next_page?: number | null;
  previous_page?: number | null;
  items: IDocument[];
}
