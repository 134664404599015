import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type {
  ChangeDownloadDocumentsIds,
  ITotalOweByEssId,
} from "../../models/billing/billing";
import type { BillingState } from "../../models/states/BillingState";
import {
  downloadDocumentMerge,
  getDocumentsHistory,
  GetDocumentsHistoryProps,
  getTotalDue,
  getTotalOwedByEssId,
  getUserBillingStatus,
} from "../actions/billing.actions";
import {
  EBillingInvoiceStatus,
  EBillingMemoStatus,
  IBillingStatusDetail,
  IDocument,
  IDocumentsGroupedResponse,
  ITotalDue,
} from "../../models/interfaces/IBilling";
import { endOfYear, format, startOfYear } from "date-fns";
import { getDocumentPaymentStatus } from "../../helpers/helperBilling";
import { EnerbitPagination } from "@enerbit/base";

const initialState: BillingState = {
  billing: {} as EnerbitPagination<IBillingStatusDetail>,
  billingStateDocuments: {},
  loadingStateDocuments: {},
  isLoadingInvoices: false,
  totalDue: 0,
  isLoadingTotalOwed: false,
  isDownloadingDocumentMerge: false,
  document_period_start: format(startOfYear(new Date()), "yyyy-MM"),
  document_period_end: format(endOfYear(new Date()), "yyyy-MM"),
  invoiceComponentsFilter: null,
  invoiceStatusFilter: null,
  filtersAmount: 1,
  essId: null,
  userId: "",
  selectedDocuments: {},
};

const getFiltersAmountChange = (
  currentValue?: EBillingMemoStatus | EBillingInvoiceStatus | string | null,
  newValue?: EBillingMemoStatus | EBillingInvoiceStatus | string | null
): number => {
  if (!currentValue && newValue != null) return 1;
  if (currentValue != null && !newValue) return -1;
  return 0;
};

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setEssId: (state, action) => {
      state.essId = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    clearBillingStatus: (state) => {
      return {...initialState}
    },
    setDocumentPeriodStart: (state, action: PayloadAction<string>) => {
      state.filtersAmount += getFiltersAmountChange(
        state.document_period_start,
        action.payload
      );
      state.document_period_start = action.payload;
    },
    setDocumentPeriodEnd: (state, action: PayloadAction<string>) => {
      state.filtersAmount += getFiltersAmountChange(
        state.document_period_end,
        action.payload
      );
      state.document_period_end = action.payload;
    },
    setInvoiceComponentsFilter: (
      state,
      action: PayloadAction<EBillingMemoStatus | null>
    ) => {
      state.filtersAmount += getFiltersAmountChange(
        state.invoiceComponentsFilter,
        action.payload
      );
      state.invoiceComponentsFilter = action.payload;
    },
    setInvoiceStatusFilter: (
      state,
      action: PayloadAction<EBillingInvoiceStatus | null>
    ) => {
      state.filtersAmount += getFiltersAmountChange(
        state.invoiceStatusFilter,
        action.payload
      );
      state.invoiceStatusFilter = action.payload;
    },
    clearDocumentsGrouped: (state) => {
      state.billingStateDocuments = {};
    },
    changeDownloadDocumentsIds: (
      state,
      action: PayloadAction<ChangeDownloadDocumentsIds>
    ) => {
      const { serviceAccountId, force, invoicePeriod } = action.payload;
      const selectedDocuments = { ...state.selectedDocuments };
      let invoicePeriods =
        selectedDocuments[serviceAccountId]?.invoicePeriods ?? [];
      let documents = selectedDocuments[serviceAccountId]?.documents ?? [];

      const invoicePeriodsSet = new Set(invoicePeriods);

      if (invoicePeriod && force == "add") {
        invoicePeriodsSet.add(invoicePeriod);
      }

      if (invoicePeriod && force == "remove") {
        invoicePeriodsSet.delete(invoicePeriod);
      }

      for (const doc of action.payload.documents) {
        const documentIsSelected = documents.some(
          (currentDoc) => currentDoc.document_id == doc.document_id
        );

        if (action.payload.force == "remove") {
          documents = documents.filter(
            (currentDoc) => currentDoc.document_id != doc.document_id
          );
          invoicePeriodsSet.delete(doc.document_period);
        } else if (action.payload.force == "add") {
          !documentIsSelected && documents.push(doc);
        } else if (documentIsSelected) {
          documents = documents.filter(
            (currentDoc) => currentDoc.document_id != doc.document_id
          );
          invoicePeriodsSet.delete(doc.document_period);
        } else if (!documentIsSelected) {
          documents.push(doc);
        }
      }

      selectedDocuments[serviceAccountId] = {
        invoicePeriods: Array.from(invoicePeriodsSet),
        documents: [...documents],
      };
      state.selectedDocuments = { ...selectedDocuments };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserBillingStatus.pending, (state, action) => {
        state.isLoadingInvoices = true;
        state.billing = {} as EnerbitPagination<IBillingStatusDetail>;
      })
      .addCase(getUserBillingStatus.rejected.type, (state) => {
        state.isLoadingInvoices = false;
      })
      .addCase(
        getUserBillingStatus.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<IBillingStatusDetail>>) => {
          state.isLoadingInvoices = false;
          state.billing = action.payload;
        }
      )
      .addCase(
        getDocumentsHistory.pending,
        (
          state,
          action: PayloadAction<
            undefined,
            string,
            { arg: GetDocumentsHistoryProps }
          >
        ) => {
          state.loadingStateDocuments[action.meta.arg.document_period] = true;
        }
      )
      .addCase(getDocumentsHistory.rejected.type, (state) => {
        state.isLoadingInvoices = false;
      })
      .addCase(
        getDocumentsHistory.fulfilled.type,
        (state, action: PayloadAction<IDocumentsGroupedResponse>) => {
          const { service_account_id, document_period } = action.payload;
          state.isLoadingInvoices = false;
          state.loadingStateDocuments[document_period] = false;

          if (state.billingStateDocuments[service_account_id] == null) {
            state.billingStateDocuments[service_account_id] = {};
          }

          let documents: IDocument[] = [];

          if (
            state.billingStateDocuments[service_account_id][document_period] !=
            null
          ) {
            documents = [
              ...state.billingStateDocuments[service_account_id][
                document_period
              ].items,
            ];
          }

          documents.push(
            ...action.payload.items.map((item) => {
              return {
                ...item,
                billing_status: getDocumentPaymentStatus(item),
              };
            })
          );

          state.billingStateDocuments[service_account_id][document_period] = {
            ...action.payload,
            items: documents,
          };
        }
      )
      .addCase(getTotalDue.pending, (state) => {
        state.isLoadingTotalOwed = true;
      })
      .addCase(getTotalDue.rejected.type, (state) => {
        state.isLoadingTotalOwed = false;
      })
      .addCase(
        getTotalDue.fulfilled.type,
        (state, action: PayloadAction<ITotalDue>) => {
          state.isLoadingTotalOwed = false;
          state.totalDue = action.payload.total_due;
        }
      )
      .addCase(getTotalOwedByEssId.pending, (state) => {
        state.isLoadingTotalOwed = true;
      })
      .addCase(getTotalOwedByEssId.rejected.type, (state) => {
        state.isLoadingTotalOwed = false;
      })
      .addCase(
        getTotalOwedByEssId.fulfilled.type,
        (state, action: PayloadAction<ITotalOweByEssId>) => {
          state.isLoadingTotalOwed = false;
          state.totalDue = action.payload.total_owe;
        }
      )
      .addCase(downloadDocumentMerge.pending, (state) => {
        state.isDownloadingDocumentMerge = true;
      })
      .addCase(downloadDocumentMerge.rejected.type, (state) => {
        state.isDownloadingDocumentMerge = false;
      })
      .addCase(
        downloadDocumentMerge.fulfilled.type,
        (state, _action: PayloadAction<ITotalDue>) => {
          state.isDownloadingDocumentMerge = false;
        }
      );
  },
});
export const {
  setDocumentPeriodStart,
  setDocumentPeriodEnd,
  setInvoiceStatusFilter,
  setInvoiceComponentsFilter,
  changeDownloadDocumentsIds,
  clearDocumentsGrouped,
  setEssId,
  setUserId,
  clearBillingStatus,
} = billingSlice.actions;
export default billingSlice.reducer;
