import moment from "moment";
import { BillingStatus } from "../models/enums/BillingStatus";
import { IDocument } from "../models/interfaces/IBilling";
import { addMonths, format } from "date-fns";
import es from "date-fns/locale/es";

export const getDocumentPaymentStatus = (
  document: IDocument
): BillingStatus => {
  const now = moment();
  if (document.payment_date != null) {
    return BillingStatus.Paid;
  } else if (
    document.document_due_at != null &&
    now.isAfter(moment(document.document_due_at))
  ) {
    return BillingStatus.Overdue;
  } else {
    return BillingStatus.Pending;
  }
};

export const formatInvoicePeriodToLocalString = (
  dateString: string
): string => {
  const date = new Date(dateString);
  const formattedDate = format(addMonths(date, 1), "MMMM/yyyy", { locale: es });
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

export const dateToInvoicePeriod = (date: Date): string => {
  const formattedDate = format(date, "yyyy-MM");
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};
