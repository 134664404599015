import {
  Alert,
  Box,
  Button,
  CloseIcon,
  Dialog,
  IconButton,
  LoadingButton,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanTransactionMessage } from "../../../features/invoice/invoice";
import { StateStorage } from "../../../models/invoiceState";
import { AppDispatch } from "../../../store/store";

export type ConfirmationDialogProps = {
  open: boolean;
  handleClose: () => void;
  onAccept: () => void;
};

export const ConfirmationDialog = ({
  handleClose,
  open,
  onAccept,
}: ConfirmationDialogProps) => {
  const { isApplyingPayments, isUploadingFile, transactionError } = useSelector(
    (state: StateStorage) => state.invoiceState
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (transactionError) {
      setTimeout(() => {
        dispatch(cleanTransactionMessage());
      }, 5000);
    }
  }, [transactionError]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <Box padding="32px" className="content-dialog-filter">
        <Box className="content-dialog-quit-button">
          <IconButton
            aria-label="Cerrar"
            sx={{ width: "24px", height: "24px" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          textAlign="center"
          variant="h5"
          color={enerbitColors.primary.main}
        >
          ¿Está seguro de aplicar este pago?
        </Typography>
        <Box className="w-100" gap="8px" display="flex">
          <Button
            fullWidth
            variant="text"
            color="primary"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            fullWidth
            disabled={isApplyingPayments || isUploadingFile}
            loading={isApplyingPayments || isUploadingFile}
            variant="contained"
            color="secondary"
            onClick={onAccept}
          >
            Si, aplica
          </LoadingButton>
        </Box>
        {transactionError && <Alert severity="error">{transactionError}</Alert>}
      </Box>
    </Dialog>
  );
};
