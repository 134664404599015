import {
  Box,
  CircularProgress,
  EnerbitPagination,
  InputAdornment,
  SearchIcon,
  TextField,
} from "@enerbit/base";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../../models/invoiceState";
import {
  isLoading,
  serviceAccountSelect,
  setServicesAccountSelect,
} from "../../../../store/slices/serviceAccountSlice";
import { getParamsUrl } from "../../../../helpers/helperAccounts";

import { AppDispatch } from "../../../../store/store";
import "./ServiceGroups.css";
import ServiceAccountCard from "./ServiceGroupCard";
import { ServicesAccount } from "../../../../models/states/ServiceAccountState";
import { getServiceAccountsRelationships } from "../../../../store/actions/serviceAccount.actions";

const ServiceGroups = () => {
  const service = useSelector(serviceAccountSelect);
  const [filterServiceAccount, setFilterServiceAccount] = useState("");

  const { servicesAccountData } = useSelector(
    (state: StateStorage) => state.serviceAccount
  );

  const { selectedDocuments } = useSelector(
    (state: StateStorage) => state.billing
  );

  const loading = useSelector(isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const scrollDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(
      getServiceAccountsRelationships({
        page: 0,
        size: 20,
      })
    );
  }, []);

  useEffect(() => {
    if (servicesAccountData.items.length > 0 && (!service || service == "")) {
      const serviceGroup = getParamsUrl("serviceGroup");
      if (serviceGroup != "") {
        dispatch(setServicesAccountSelect(serviceGroup));
      } else {
        dispatch(
          setServicesAccountSelect(
            servicesAccountData.items[0].service_account_id
          )
        );
      }
    }
  }, [servicesAccountData]);

  const handleScroll = () => {
    const scrollDiv = scrollDivRef.current;

    if (scrollDiv) {
      const { scrollTop, scrollHeight, clientHeight } = scrollDiv;

      if (
        scrollTop + clientHeight >= scrollHeight - 100 &&
        !loading &&
        servicesAccountData.next_page != null
      ) {
        dispatch(
          getServiceAccountsRelationships({
            page: servicesAccountData.next_page,
            size: 20,
          })
        );
      }
    }
  };

  const selectedServiceAccounts = (serviceAccountId: string): number => {
    const documents = selectedDocuments[serviceAccountId]?.documents ?? [];
    return documents.length;
  };

  useEffect(() => {
    const scrollDiv = scrollDivRef.current;

    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading]);

  const filteredServicesAccountData: EnerbitPagination<ServicesAccount> = {
    ...servicesAccountData,
    items: servicesAccountData.items.filter((item) => {
      const regex = RegExp(filterServiceAccount, "ig");
      return (
        item.personalizations.some((personalization) =>
          regex.test(personalization.alias)
        ) ||
        regex.test(item.estate_address ?? "") ||
        regex.test(item.service_account_id)
      );
    }),
  };

  return (
    <div className="service-group-container">
      <Box ref={scrollDivRef} sx={{ overflowX: "auto", maxHeight: "86vh" }}>
        {loading && filteredServicesAccountData.items.length == 0 ? (
          <Box className="Loading">
            <CircularProgress />
          </Box>
        ) : (
          <Box paddingTop={0}>
            <h1>Seleccionar grupo</h1>
            <Box
              className="TextField-without-border-radius Text-select-field"
              sx={{ mb: "15px" }}
            >
              <TextField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterServiceAccount(event.target.value);
                }}
                placeholder="Buscar"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ className: "service-group-search" }}
                SelectProps={{ style: { borderRadius: "4px" } }}
                sx={{ width: "100%" }}
              />
            </Box>
            {filteredServicesAccountData.items.map((value, index) => (
              <ServiceAccountCard
                onClick={() =>
                  dispatch(setServicesAccountSelect(value.service_account_id))
                }
                amountSelected={selectedServiceAccounts(
                  value.service_account_id
                )}
                serviceAccount={value}
                key={`service-group-card-${value.service_account_id}`}
              />
            ))}
            {loading && filteredServicesAccountData.items.length != 0 && (
              <Box
                width="100%"
                sx={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default ServiceGroups;
