import { startOfYear, endOfYear } from "date-fns";
import "../BillingPage.css";
import { Box } from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../models/invoiceState";
import {
  setInvoiceComponentsFilter,
  setInvoiceStatusFilter,
  setDocumentPeriodEnd,
  setDocumentPeriodStart,
} from "../../../store/slices/billingSlice";
import { AppDispatch } from "../../../store/store";
import { getUserBillingStatus } from "../../../store/actions/billing.actions";
import { serviceAccountSelect } from "../../../store/slices/serviceAccountSlice";
import {
  EBillingInvoiceStatus,
  EBillingMemoStatus,
} from "../../../models/interfaces/IBilling";
import { dateToInvoicePeriod as dateToInvoicePeriod } from "../../../helpers/helperBilling";
import DateRangePickerInput from "./DateRangePickerInput";

interface InvoiceFiltersCardProps {
  onHideFiltersModal: () => void;
}

const InvoiceFiltersCard = ({
  onHideFiltersModal,
}: InvoiceFiltersCardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const serviceAccountId = useSelector(serviceAccountSelect);
  const {
    document_period_start,
    document_period_end,
    invoiceComponentsFilter,
    invoiceStatusFilter,
    essId,
    userId,
  } = useSelector((state: StateStorage) => state.billing);

  const handleComponentChange = (component: EBillingMemoStatus | null) => {
    dispatch(setInvoiceComponentsFilter(component));
  };

  const handleStatusChange = (status: EBillingInvoiceStatus | null) => {
    dispatch(setInvoiceStatusFilter(status));
  };

  const clearFilters = () => {
    dispatch(setInvoiceComponentsFilter(null));
    dispatch(setInvoiceStatusFilter(null));
    dispatch(
      setDocumentPeriodStart(dateToInvoicePeriod(startOfYear(new Date())))
    );
    dispatch(setDocumentPeriodEnd(dateToInvoicePeriod(endOfYear(new Date()))));
  };

  const applyFilters = () => {
    dispatch(
      getUserBillingStatus({
        user_id: userId,
        document_period_start,
        document_period_end,
        service_account_ids: serviceAccountId ? [serviceAccountId] : [],
        document_components: invoiceComponentsFilter,
        document_state: invoiceStatusFilter,
        page: 0,
        service_id: essId,
      })
    );
    onHideFiltersModal();
  };

  return (
    <div className="filter-card">
      <div style={{ padding: "16px" }}>
        <h3>Filtros</h3>
        <Box display="flex">
          <DateRangePickerInput />
        </Box>
        <div className="filter-section">
          <label>Componentes de la factura</label>
          <div className="filter-options">
            <button
              onClick={() => handleComponentChange(null)}
              className={`filter-chip ${
                !invoiceComponentsFilter ? "active" : ""
              }`}
            >
              todos
            </button>
            <button
              onClick={() =>
                handleComponentChange(EBillingMemoStatus.InvoicesWithMemos)
              }
              className={`filter-chip ${
                invoiceComponentsFilter === EBillingMemoStatus.InvoicesWithMemos
                  ? "active"
                  : ""
              }`}
            >
              facturas con ajustes
            </button>
            <button
              onClick={() =>
                handleComponentChange(EBillingMemoStatus.InvoicesWithDebitNote)
              }
              className={`filter-chip ${
                invoiceComponentsFilter ===
                EBillingMemoStatus.InvoicesWithDebitNote
                  ? "active"
                  : ""
              }`}
            >
              facturas con notas débito
            </button>
            <button
              onClick={() =>
                handleComponentChange(EBillingMemoStatus.InvoicesWithCreditNote)
              }
              className={`filter-chip ${
                invoiceComponentsFilter ===
                EBillingMemoStatus.InvoicesWithCreditNote
                  ? "active"
                  : ""
              }`}
            >
              facturas con notas crédito
            </button>
          </div>
        </div>
        <div className="filter-section">
          <label>Estado de la factura</label>
          <div className="filter-options">
            <button
              onClick={() => handleStatusChange(null)}
              className={`filter-chip ${!invoiceStatusFilter ? "active" : ""}`}
            >
              todos
            </button>
            <button
              onClick={() =>
                handleStatusChange(EBillingInvoiceStatus.PendingDocuments)
              }
              className={`filter-chip ${
                invoiceStatusFilter === EBillingInvoiceStatus.PendingDocuments
                  ? "active"
                  : ""
              }`}
            >
              facturas por pagar
            </button>
            <button
              onClick={() => handleStatusChange(EBillingInvoiceStatus.UpToDate)}
              className={`filter-chip ${
                invoiceStatusFilter === EBillingInvoiceStatus.UpToDate
                  ? "active"
                  : ""
              }`}
            >
              facturas al día
            </button>
          </div>
        </div>
      </div>
      <div className="filter-actions">
        <button onClick={clearFilters}>Limpiar todos</button>
        <button onClick={applyFilters}>Aplicar filtros</button>
      </div>
    </div>
  );
};

export default InvoiceFiltersCard;
