import {
  Box,
  CircularProgress,
  IconButton,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  enerbitColors,
  formatterPeso,
} from "@enerbit/base";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { StateStorage } from "../../../../models/invoiceState";
import {
  downloadDocumentMerge,
  getDocumentsHistory,
  getUserBillingStatus,
} from "../../../../store/actions/billing.actions";
import { AppDispatch } from "../../../../store/store";
import {
  EBillingInvoiceStatus,
  EBillingMemoStatus,
  IDocument,
} from "../../../../models/interfaces/IBilling";
import "../../BillingPage.css";
import DownloadButton from "../DownloadButton";
import {
  changeDownloadDocumentsIds,
  clearDocumentsGrouped,
} from "../../../../store/slices/billingSlice";
import BillingStatusChip from "../BillingStatusChip";
import { BillingStatus } from "../../../../models/enums/BillingStatus";
import { serviceAccountSelect } from "../../../../store/slices/serviceAccountSlice";
import { DocumentsList } from "./DocumentsList";
import { formatInvoicePeriodToLocalString } from "../../../../helpers/helperBilling";

export const TableInvoice = () => {
  const dispatch = useDispatch<AppDispatch>();
  const serviceAccountId = useSelector(serviceAccountSelect);
  const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({});
  const {
    billing,
    billingStateDocuments,
    document_period_end,
    isLoadingInvoices,
    document_period_start,
    selectedDocuments,
    essId,
    userId,
  } = useSelector((state: StateStorage) => state.billing);

  const [loadingButtons, setLoadingButtons] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(billing?.page ?? 1);

  useEffect(() => {
    setCurrentPage(1);
    setOpenRows({});
    dispatch(clearDocumentsGrouped());
  }, [serviceAccountId]);

  const handleRowClick = (invoice_period: string) => {
    setOpenRows((prevOpenRows) => {
      const prevState = prevOpenRows[invoice_period] ?? false;

      if (
        getServiceDocuments(invoice_period).length == 0 &&
        prevState == false
      ) {
        dispatch(
          getDocumentsHistory({
            document_period: invoice_period,
            service_account_id: serviceAccountId,
            user_id: userId,
            is_paid: null,
            page: 0,
            service_id: essId ?? undefined,
          })
        );
      }

      return {
        ...prevOpenRows,
        [invoice_period]: !prevState,
      };
    });
  };

  const getServiceDocuments = (invoice_period: string): IDocument[] => {
    const servicePeriods = billingStateDocuments[serviceAccountId] ?? {};
    return servicePeriods[invoice_period]?.items ?? [];
  };

  const handleDownloadClick = (
    index: number,
    serviceAccountId: string,
    invoicePeriod: string
  ) => {
    setLoadingButtons((prev) => [...prev, index]);
    dispatch(
      downloadDocumentMerge({
        userId,
        service_accounts_data: [
          {
            service_account_id: serviceAccountId,
            invoice_periods: [invoicePeriod],
          },
        ],
      })
    ).finally(() => {
      setLoadingButtons((prev) => prev.filter((i) => i !== index));
    });
  };

  const handlePageChange = (_event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    dispatch(
      getUserBillingStatus({
        page: value - 1,
        user_id: userId,
        document_state: null,
        document_period_start,
        document_period_end: document_period_end ?? null,
        document_components: null,
        service_account_ids: serviceAccountId ? [serviceAccountId] : [],
        service_id: essId,
      })
    );
  };

  const shouldCheckPeriodAccordion = (
    serviceAccountId: string,
    invoicePeriod: string
  ): boolean => {
    const serviceAccount = selectedDocuments[serviceAccountId];
    const periods = serviceAccount?.invoicePeriods ?? [];
    return periods.some((period) => period === invoicePeriod);
  };

  const handlePeriodAccordionChange = (
    value: boolean,
    invoicePeriod: string,
    documents: IDocument[]
  ): void => {
    const documentsIds = documents
      .filter((doc) => doc.document_id != null)
      .map((doc) => doc);

    if (value) {
      dispatch(
        changeDownloadDocumentsIds({
          documents: documentsIds,
          invoicePeriod,
          serviceAccountId: serviceAccountId,
          force: "add",
        })
      );
    } else {
      dispatch(
        changeDownloadDocumentsIds({
          documents: documentsIds,
          invoicePeriod,
          serviceAccountId,
          force: "remove",
        })
      );
    }
  };

  const mapMemoAndBillingStatus = (
    billingStatus: EBillingInvoiceStatus | EBillingMemoStatus
  ): BillingStatus => {
    const statuses = {
      [EBillingInvoiceStatus.UpToDate]: BillingStatus.Paid,
      [EBillingInvoiceStatus.PendingDocuments]: BillingStatus.Pending,
      [EBillingInvoiceStatus.ExpiredDocuments]: BillingStatus.Overdue,
      [EBillingMemoStatus.InvoicesWithCreditNote]: BillingStatus.CreditNote,
      [EBillingMemoStatus.InvoicesWithDebitNote]: BillingStatus.DebitNote,
      [EBillingMemoStatus.InvoicesWithMemos]: BillingStatus.InvoiceWithMemos,
    };

    return statuses[billingStatus];
  };

  if (isLoadingInvoices) {
    return (
      <Box className="Loading-flex">
        <CircularProgress />
      </Box>
    );
  }

  const cellStyle = {
    borderBottom: "none",
    backgroundColor: "#F9FAFB !important",
  };

  return (
    <>
      <TableContainer sx={{ boxShadow: "none", mt: "20px" }} component={Paper}>
        <Table className="Table-invoice-settlement">
          <TableHead>
            <TableRow
              sx={{
                borderRadius: "8px",
              }}
            >
              <TableCell align="left" width={"20%"}>
                Período facturado
              </TableCell>
              <TableCell align="center" width={"30%"}>
                Estado
              </TableCell>
              <TableCell align="right" width={"10%"}>
                Valor total
              </TableCell>
              <TableCell align="right" width={"30%"}></TableCell>
              <TableCell align="left" width={"10%"}>
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {billing &&
              billing.items?.map((value, index: number) => {
                const documents = getServiceDocuments(value.invoice_period);
                const groupIsChecked = shouldCheckPeriodAccordion(
                  serviceAccountId,
                  value.invoice_period
                );
                return (
                  <>
                    <TableRow
                      key={`${value.invoice_period}-header`}
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    >
                      <TableCell
                        className="Table-invoices-td"
                        sx={{
                          ...cellStyle,
                          fontWeight: "700",
                          borderRadius: "15px 0px 0px 15px",
                          borderBottom: "none",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="documents-checkbox"
                          checked={groupIsChecked}
                          onChange={() =>
                            handlePeriodAccordionChange(
                              !groupIsChecked,
                              value.invoice_period,
                              documents ?? []
                            )
                          }
                          style={{ marginRight: "10px", marginLeft: "15px" }}
                        />{" "}
                        {formatInvoicePeriodToLocalString(value.invoice_period)}
                      </TableCell>
                      <TableCell
                        className="Table-invoices-td"
                        align="center"
                        sx={cellStyle}
                      >
                        {
                          <BillingStatusChip
                            key={`${
                              value.invoice_period
                            }-${value.billing_status.toString()}`}
                            type={mapMemoAndBillingStatus(value.billing_status)}
                          />
                        }
                        {value.info_memos && (
                          <BillingStatusChip
                            key={`${
                              value.invoice_period
                            }-${value.info_memos.toString()}`}
                            type={mapMemoAndBillingStatus(value.info_memos)}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        className="Table-invoices-td"
                        align="right"
                        sx={{
                          ...cellStyle,
                          fontWeight: "bold",
                        }}
                      >
                        {formatterPeso.format(value.total!)}
                      </TableCell>
                      <TableCell
                        className="Table-invoices-td"
                        sx={cellStyle}
                      ></TableCell>
                      <TableCell
                        className="Table-invoices-td"
                        sx={{
                          ...cellStyle,
                          display: "flex",
                          justifyContent: "end",
                          borderRadius: "0px 15px 15px 0px",
                        }}
                      >
                        <DownloadButton
                          loading={loadingButtons.includes(index)}
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            handleDownloadClick(
                              index,
                              serviceAccountId,
                              value.invoice_period
                            );
                          }}
                        />
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          style={{ marginRight: "15px", marginLeft: "10px" }}
                          onClick={() => handleRowClick(value.invoice_period)}
                        >
                          {openRows[value.invoice_period] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow key={`${value.invoice_period}-body`}>
                      <TableCell
                        colSpan={6}
                        sx={{
                          ...cellStyle,
                          padding: "6px",
                          backgroundColor: "white !important",
                        }}
                      >
                        <DocumentsList
                          userId={userId}
                          openRows={openRows}
                          invoicePeriod={value.invoice_period}
                          serviceAccountId={serviceAccountId}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>

        <Box className="Pagination-tables">
          <Pagination
            count={billing?.pages ?? 0}
            shape="rounded"
            page={currentPage}
            sx={{ margin: "0.3rem 0 1rem 0" }}
            onChange={handlePageChange}
          />
        </Box>
      </TableContainer>
    </>
  );
};
