import { EnerbitPagination } from "@enerbit/base";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ServiceAccountState,
  ServicesAccount,
} from "../../models/states/ServiceAccountState";
import { StateStorage } from "../../models/invoiceState";
import { getServiceAccountsRelationships } from "../actions/serviceAccount.actions";

export interface GetServiceAccountsRelationships {
  page: number;
  size: number;
}

const initialState: ServiceAccountState = {
  hasError: false,
  isLoading: false,
  servicesAccountData: {
    items: [],
    page: 0,
    pages: 0,
    size: 0,
    total: 0,
    next_page: 0,
  },
  serviceAccountSelect: "",
};

export const serviceAccountSlice = createSlice({
  name: "serviceAccount",
  initialState,
  reducers: {
    setDefaultServicesAccountData: (state) => {
      state.servicesAccountData = {
        items: [],
        page: 0,
        pages: 0,
        size: 0,
        total: 0,
        next_page: 0,
      };
    },
    setServicesAccountSelect: (state, { payload }) => {
      state.serviceAccountSelect = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceAccountsRelationships.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getServiceAccountsRelationships.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<ServicesAccount>>) => {
          const serviceAccounts = [
            ...state.servicesAccountData.items,
            ...action.payload.items,
          ];
          const uniqueServiceAccounts = serviceAccounts.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          );
          state.servicesAccountData = {
            ...action.payload,
            items: uniqueServiceAccounts,
          };
          state.isLoading = false;
        }
      )
      .addCase(getServiceAccountsRelationships.rejected.type, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export const { setServicesAccountSelect, setDefaultServicesAccountData } =
  serviceAccountSlice.actions;

export const hasError = (state: StateStorage) => state.serviceAccount.hasError;
export const isLoading = (state: StateStorage) =>
  state.serviceAccount.isLoading;
export const serviceAccountSelect = (state: StateStorage) =>
  state.serviceAccount.serviceAccountSelect;

export default serviceAccountSlice.reducer;
