import { initEnviroment } from "@enerbit/base";
import Routers from "./router/Routers";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export type InvoicesMicroProps = {
  document_group_id?: string;
  ess_id?: string;
  service_account_id: string;
  user_id: string;
};

export default function Root({
  user_id,
  document_group_id,
  ess_id,
  service_account_id,
}: InvoicesMicroProps) {
  return (
    <Routers
      user_id={user_id}
      ess_id={ess_id}
      document_group_id={document_group_id}
      service_account_id={service_account_id}
    />
  );
}
