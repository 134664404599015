import {
  Box,
  DeleteOutlineIcon,
  IconButton,
  Typography,
  enerbitColors,
  formatterPeso,
  serviceNames,
} from "@enerbit/base";
import moment from "moment";
import { IDocument } from "../../models/interfaces/IBilling";

export type CardResumeItemProps = {
  document: IDocument;
  onDelete: () => void;
};

export const CardResumeItem = ({
  document: documentGroup,
  onDelete,
}: CardResumeItemProps) => {
  return (
    <Box className="box-card-resume">
      <Box className="box-title-card-resume">
        <Typography
          variant="subtitle1"
          fontSize={16}
          fontWeight="bold"
          color={enerbitColors.neutral[700]}
        >
          {serviceNames[documentGroup.document_service_type]} (
          {moment(documentGroup.document_period).format("MMMM YYYY")})
        </Typography>
        <Typography
          variant="subtitle1"
          fontSize={12}
          color={enerbitColors.neutral.main}
        >
          {documentGroup.document_id}
        </Typography>
      </Box>
      <Typography
        variant="h6"
        fontWeight="bold"
        color={enerbitColors.neutral[700]}
      >
        {formatterPeso.format(documentGroup.payable_amount)}
      </Typography>
      <IconButton
        aria-label="Eliminar"
        sx={{ width: "24px", height: "24px" }}
        color="error"
        onClick={onDelete}
      >
        <DeleteOutlineIcon />
      </IconButton>
    </Box>
  );
};
