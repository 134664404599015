import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../models/invoiceState";
import { AppDispatch } from "../../../store/store";
import { DateRangePicker, RangeKeyDict, StaticRange } from "react-date-range";
import {
  setDocumentPeriodEnd,
  setDocumentPeriodStart,
} from "../../../store/slices/billingSlice";
import { dateToInvoicePeriod } from "../../../helpers/helperBilling";
import { useState } from "react";
import { CalendarIcon, InputAdornment, TextField } from "@enerbit/base";
import es from "date-fns/locale/es";
import { format, subMonths } from "date-fns";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns";
import { startOfYear } from "date-fns";
import { endOfYear } from "date-fns";
import { subYears } from "date-fns";

const DateRangePickerInput = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { document_period_start, document_period_end } = useSelector(
    (state: StateStorage) => state.billing
  );

  const [focusedInput, setFocusedInput] = useState<string | null>(null);

  const handleDateChange = (ranges: RangeKeyDict) => {
    const { selection } = ranges;
    selection.startDate &&
      dispatch(
        setDocumentPeriodStart(dateToInvoicePeriod(selection.startDate))
      );
    dispatch(
      setDocumentPeriodEnd(
        selection.endDate
          ? dateToInvoicePeriod(selection.endDate)
          : format(endOfYear(new Date()), "yyyy-MM")
      )
    );
    setFocusedInput(null);
  };

  const inputValue = `${document_period_start} - ${document_period_end}`;

  return (
    <div
      className="calendar-container"
      style={{ position: "relative", width: "100%", marginBottom: "15px" }}
    >
      <TextField
        value={inputValue}
        onFocus={() => setFocusedInput("startDate")}
        aria-readonly={true}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ className: "calendar-input" }}
        sx={{ width: "100%" }}
      />
      {focusedInput && (
        <div style={{ position: "absolute" }}>
          <DateRangePicker
            locale={es}
            editableDateInputs={false}
            onChange={handleDateChange}
            moveRangeOnFirstSelection={false}
            ranges={[
              {
                startDate: new Date(document_period_start),
                endDate:
                  document_period_end != null
                    ? new Date(document_period_end)
                    : undefined,
                key: "selection",
              },
            ]}
            months={1}
            staticRanges={[
              {
                label: "Este mes",
                range: () => ({
                  startDate: startOfMonth(new Date()),
                  endDate: endOfMonth(new Date()),
                }),
                isSelected: () => false,
              },
              {
                label: "Últimos tres meses",
                range: () => ({
                  startDate: startOfMonth(subMonths(new Date(), 3)),
                  endDate: endOfMonth(new Date()),
                }),
                isSelected: () => false,
              },
              {
                label: "Este año",
                range: () => ({
                  startDate: startOfYear(new Date()),
                  endDate: endOfYear(new Date()),
                }),
                isSelected: () => false,
              },
              {
                label: "El año pasado",
                range: () => ({
                  startDate: startOfYear(subYears(new Date(), 1)),
                  endDate: endOfYear(subYears(new Date(), 1)),
                }),
                isSelected: () => false,
              },
            ]}
            inputRanges={[]}
            renderStaticRangeLabel={(staticRange: StaticRange) => {
              return <span>{staticRange.label ?? ""}</span>;
            }}
            direction="horizontal"
            dateDisplayFormat="yyyy-MM"
          />
        </div>
      )}
    </div>
  );
};

export default DateRangePickerInput;
