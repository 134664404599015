import {
  Box,
  Button,
  CloseIcon,
  Divider,
  IconButton,
  Typography,
  enerbitColors,
  formatterPeso,
} from "@enerbit/base";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { onDocumentGroupCheckChanged } from "../../../features/invoice/invoice";
import { DocumentGrouped } from "../../../models/DocumentsGrouped";
import { StateStorage } from "../../../models/invoiceState";
import { AppDispatch } from "../../../store/store";
import { CardResumeItem } from "../../components/CardResumeItem";
import { IDocument } from "../../../models/interfaces/IBilling";
import { BillingStatus } from "../../../models/enums/BillingStatus";
import { changeDownloadDocumentsIds } from "../../../store/slices/billingSlice";
import { serviceAccountSelect } from "../../../store/slices/serviceAccountSlice";

export type FiltersDialogProps = {
  open: boolean;
  handleClose: () => void;
  onApplyPaymentClicked: () => void;
};

export const ResumeDialog = ({
  open,
  handleClose,
  onApplyPaymentClicked,
}: FiltersDialogProps) => {
  const serviceAccountId = useSelector(serviceAccountSelect);
  const { documentsGroupedUnPayed } = useSelector(
    (state: StateStorage) => state.invoiceState
  );

  const { selectedDocuments } = useSelector(
    (state: StateStorage) => state.billing
  );

  const [documentsSelected, setDocumentsSelected] = useState<IDocument[]>([]);

  const dispatch = useDispatch<AppDispatch>();

  const getDocumentsGroupSelected = (): DocumentGrouped[] => {
    return documentsGroupedUnPayed.document_groups.filter(
      (documentGroup) => documentGroup.isSelected
    );
  };

  const getTotalDocumentsGroupSelected = (): number => {
    let total = 0;
    for (let i = 0; i < documentsSelected.length; i++) {
      total += documentsSelected[i].payable_amount;
    }
    return total;
  };

  useEffect(() => {
    const documentsWithDue = Object.values(selectedDocuments)
      .map((doc) => {
        return doc.documents.filter((doc) =>
          [BillingStatus.Overdue, BillingStatus.Pending].includes(
            doc.billing_status
          )
        );
      })
      .flat();

    setDocumentsSelected(documentsWithDue);
  }, [selectedDocuments]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <Box padding="32px" className="content-dialog-filter">
        <Box className="content-dialog-quit-button">
          <IconButton
            aria-label="Cerrar"
            sx={{ width: "24px", height: "24px" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="box-resume-dialog">
          <Typography
            variant="h5"
            color={enerbitColors.primary.main}
            fontWeight="bold"
          >
            Resumen
          </Typography>
          {documentsSelected.map((document) => {
            return (
              <CardResumeItem
                key={uuid()}
                document={document}
                onDelete={() => {
                  dispatch(
                    changeDownloadDocumentsIds({
                      documents: [document],
                      serviceAccountId: serviceAccountId,
                      force: "remove",
                    })
                  );
                }}
              />
            );
          })}
          <Divider sx={{ width: "100%" }} />
          <Box className="box-total-to-pay">
            <Typography variant="h6" color={enerbitColors.neutral[700]}>
              Total a pagar
            </Typography>
            <Typography
              variant="h6"
              fontSize={20}
              fontWeight="bold"
              color={enerbitColors.neutral[700]}
            >
              {formatterPeso.format(getTotalDocumentsGroupSelected())}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onApplyPaymentClicked}
          >
            Aplicar pago
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
