import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../../models/invoiceState";
import { Box, Collapse, Skeleton } from "@enerbit/base";
import { IDocumentsGroupedResponse } from "../../../../models/interfaces/IBilling";
import { CardItemTableInvoice } from "./CardItemTableInvoice";
import { useEffect, useRef } from "react";
import { getDocumentsHistory } from "../../../../store/actions/billing.actions";
import { AppDispatch } from "../../../../store/store";

interface DocumentsListProps {
  invoicePeriod: string;
  serviceAccountId: string;
  userId: string;
  openRows: { [key: string]: boolean };
}

export const DocumentsList = ({
  invoicePeriod,
  serviceAccountId,
  openRows,
  userId,
}: DocumentsListProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const { loadingStateDocuments, billingStateDocuments } = useSelector(
    (state: StateStorage) => state.billing
  );

  const handleScroll = () => {
    const scrollDiv = scrollDivRef.current;

    if (scrollDiv) {
      const { scrollTop, scrollHeight, clientHeight } = scrollDiv;
      const documentsHistory = getServiceDocuments(invoicePeriod);

      if (
        scrollTop + clientHeight >= scrollHeight - 70 &&
        !loadingStateDocuments[invoicePeriod] &&
        documentsHistory?.next_page != null
      ) {
        dispatch(
          getDocumentsHistory({
            document_period: invoicePeriod,
            service_account_id: serviceAccountId,
            user_id: userId,
            is_paid: null,
            page: documentsHistory.next_page,
          })
        );
      }
    }
  };

  useEffect(() => {
    const scrollDiv = scrollDivRef.current;

    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadingStateDocuments[invoicePeriod]]);

  const getServiceDocuments = (
    invoice_period: string
  ): IDocumentsGroupedResponse => {
    const servicePeriods = billingStateDocuments[serviceAccountId] ?? {};
    return servicePeriods[invoice_period] ?? {};
  };

  return (
    <Box ref={scrollDivRef} sx={{ overflowX: "auto", maxHeight: "300px" }}>
      {getServiceDocuments(invoicePeriod)?.items?.map((document) => (
        <Collapse
          key={document.document_id}
          in={openRows[invoicePeriod]}
          timeout="auto"
          unmountOnExit
          sx={{ background: "white" }}
        >
          <CardItemTableInvoice document={document} />
        </Collapse>
      ))}
      {loadingStateDocuments[invoicePeriod] && (
        <>
          <Skeleton
            variant="rounded"
            height={60}
            style={{ marginBottom: "5px", marginLeft: "1%" }}
          />
          <Skeleton
            variant="rounded"
            height={60}
            style={{ marginBottom: "5px", marginLeft: "1%" }}
          />
          <Skeleton
            variant="rounded"
            height={60}
            style={{ marginLeft: "1%" }}
          />
        </>
      )}
    </Box>
  );
};
