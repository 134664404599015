import { EnerbitPagination } from "@enerbit/base";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { AxiosError } from "axios";
import {
  DocumentGrouped,
  PaginationDocumentGrouped,
} from "../../models/DocumentsGrouped";
import { InvoiceState } from "../../models/invoiceState";

import { AppUser } from "../../models/AppUser";
import { DocumentComponents } from "../../models/DocumentComponents";
import { Document } from "../../models/DocumentsGrouped";
import { FileResponse } from "../../models/FileResponse";
import { ServiceAccountRelationship } from "../../models/ServiceAccount";
import { TotalDue } from "../../models/TotalDue";
import {
  FilterOptions,
  GetDocumentsGroupedProps,
  donwloadInvoice,
  downloadMemo,
  getDocumentsGrouped,
  getInvoiceComponents,
  getMemoComponents,
  getServiceAccountRelationships,
  getTotalDue,
} from "../actions/actions";
import {
  createTransaction,
  uploadPaymentReceipt,
} from "../actions/payments/payments.actions";
import { getUserById } from "../actions/users/users.actions";

const initialState: InvoiceState = {
  isLoadingInvoiceComponents: false,
  hasErrorInvoiceGroup: false,
  invoiceDownload: "",
  isLoadingDownload: false,
  hasErrorMemoDownload: false,
  isLoadingDownloadMemo: false,
  isApplyingPayments: false,
  isUploadingFile: false,
  fileResponse: null,
  filters: {
    month: null,
  },
  errorDownload: false,
  successDownload: false,
  isLoadingTotalDue: false,
  documentsGroupedPayed: {
    document_groups: [],
    next_invoice_period: null,
    user_id: null,
  },
  documentsGroupedUnPayed: {
    document_groups: [],
    next_invoice_period: null,
    user_id: null,
  },
  isLoadingDocumentGroupedUnPayed: false,
  isLoadingDocumentGroupedPayed: false,
  totalDue: null,
  documentSelected: null,
  documentComponents: null,
  isLoadingServiceAccounts: false,
  serviceAccounts: null,
  transactionError: null,
  isTransactionCreated: false,
  appUser: null,
  isLoadingUser: false,
  timesPayedFeched: 0,
  timesUnPayedFeched: 0,
};

export type OnInvoceGroupCheckChangedProps = {
  checked: boolean;
  documentGroup: DocumentGrouped;
};

export const invoiceSlice = createSlice({
  name: "invoiceState",
  initialState,
  reducers: {
    onDocumentSelectedChanged: (
      state,
      action: PayloadAction<Document | null>
    ) => {
      state.documentSelected = action.payload;
    },
    setResetVars: (state) => {
      state.errorDownload = false;
      state.successDownload = false;
      state.hasErrorInvoiceGroup = false;
    },
    onDocumentGroupCheckChanged: (
      state,
      action: PayloadAction<OnInvoceGroupCheckChangedProps>
    ) => {
      const index = state.documentsGroupedUnPayed.document_groups.findIndex(
        (documentGroup) =>
          documentGroup.id == action.payload.documentGroup.id &&
          documentGroup.invoice_period ==
            action.payload.documentGroup.invoice_period
      );
      if (index > -1) {
        state.documentsGroupedUnPayed.document_groups[index] = {
          ...state.documentsGroupedUnPayed.document_groups[index],
          isSelected: action.payload.checked,
        };
      }
    },
    onDocumentGroupExpandedChanged: (
      state,
      action: PayloadAction<DocumentGrouped>
    ) => {
      // TODO: Optimizar este metodo
      const index = state.documentsGroupedUnPayed.document_groups.findIndex(
        (documentGroup) =>
          documentGroup.id == action.payload.id &&
          documentGroup.invoice_period == action.payload.invoice_period
      );
      if (index > -1) {
        state.documentsGroupedUnPayed.document_groups[index] = {
          ...state.documentsGroupedUnPayed.document_groups[index],
          isExpanded: !action.payload.isExpanded,
        };
        return;
      }
      const j = state.documentsGroupedPayed.document_groups.findIndex(
        (documentGroup) =>
          documentGroup.id == action.payload.id &&
          documentGroup.invoice_period == action.payload.invoice_period
      );
      if (j > -1) {
        state.documentsGroupedPayed.document_groups[j] = {
          ...state.documentsGroupedPayed.document_groups[j],
          isExpanded: !action.payload.isExpanded,
        };
        return;
      }
    },
    onAllUnPayedGroupsCheckChanged: (state, action: PayloadAction<boolean>) => {
      state.documentsGroupedUnPayed.document_groups =
        state.documentsGroupedUnPayed.document_groups.map((documentGroup) => {
          documentGroup.isSelected = action.payload;
          return documentGroup;
        });
    },
    onComponentsSelectedCleaned: (state) => {
      state.documentComponents = null;
    },
    onFiltersChanged: (state, action: PayloadAction<FilterOptions>) => {
      state.filters = action.payload;
    },
    cleanTransactionMessage: (state) => {
      state.transactionError = null;
    },
    resetTransaction: (state) => {
      state.transactionError = null;
      state.isTransactionCreated = false;
    },
    onCleanUnPayedGroups: (state) => {
      state.documentsGroupedUnPayed = {
        document_groups: [],
        next_invoice_period: null,
        user_id: null,
      };
      state.timesUnPayedFeched = 0;
    },
    onCleanPayedGroups: (state) => {
      state.documentsGroupedPayed = {
        document_groups: [],
        next_invoice_period: null,
        user_id: null,
      };
      state.timesPayedFeched = 0;
    },
    onFiltersCleaned: (state) => {
      state.filters = {
        month: null,
      };
    },
    onCleanServiceAccounts: (state) => {
      state.serviceAccounts = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getDocumentsGrouped.pending.type,
        (
          state,
          action: PayloadAction<
            undefined,
            string,
            { arg: GetDocumentsGroupedProps }
          >
        ) => {
          if (action.meta.arg.is_paid) {
            state.isLoadingDocumentGroupedPayed = true;
            if (action.meta.arg.reset_data) {
              state.documentsGroupedPayed = {
                document_groups: [],
                next_invoice_period: null,
                user_id: action.meta.arg.user_id,
              };
              state.timesPayedFeched = 0;
            }
          } else {
            state.isLoadingDocumentGroupedUnPayed = true;
            if (action.meta.arg.reset_data) {
              state.documentsGroupedUnPayed = {
                document_groups: [],
                next_invoice_period: null,
                user_id: action.meta.arg.user_id,
              };
              state.timesUnPayedFeched = 0;
            }
          }
        }
      )
      .addCase(
        getDocumentsGrouped.rejected.type,
        (
          state,
          action: PayloadAction<
            undefined,
            string,
            { arg: GetDocumentsGroupedProps }
          >
        ) => {
          if (action.meta.arg.is_paid) {
            state.isLoadingDocumentGroupedPayed = false;
            state.timesPayedFeched += 1;
          } else {
            state.isLoadingDocumentGroupedUnPayed = false;
            state.timesUnPayedFeched += 1;
          }
        }
      )
      .addCase(
        getDocumentsGrouped.fulfilled.type,
        (
          state,
          action: PayloadAction<
            PaginationDocumentGrouped,
            string,
            { arg: GetDocumentsGroupedProps }
          >
        ) => {
          if (action.meta.arg.is_paid) {
            state.isLoadingDocumentGroupedPayed = false;
            const response = [
              ...state.documentsGroupedPayed.document_groups,
              ...action.payload.document_groups,
            ];
            const unique = response.filter((obj, index) => {
              return (
                index ===
                response.findIndex(
                  (doc) =>
                    obj.invoice_period === doc.invoice_period &&
                    obj.id === doc.id
                )
              );
            });
            state.documentsGroupedPayed = {
              ...action.payload,
              next_invoice_period: action.payload.next_invoice_period || null,
              document_groups: unique,
              user_id: action.meta.arg.user_id,
            };
            state.timesPayedFeched += 1;
          } else {
            state.isLoadingDocumentGroupedUnPayed = false;
            const response = [
              ...state.documentsGroupedUnPayed.document_groups,
              ...action.payload.document_groups,
            ];
            const unique = response.filter((obj, index) => {
              return (
                index ===
                response.findIndex(
                  (doc) =>
                    obj.invoice_period === doc.invoice_period &&
                    obj.id === doc.id
                )
              );
            });
            state.documentsGroupedUnPayed = {
              ...action.payload,
              next_invoice_period: action.payload.next_invoice_period || null,
              document_groups: unique,
              user_id: action.meta.arg.user_id,
            };
            state.timesUnPayedFeched += 1;
          }
        }
      )

      .addCase(donwloadInvoice.pending.type, (state) => {
        state.isLoadingDownload = true;
      })
      .addCase(donwloadInvoice.rejected.type, (state) => {
        state.isLoadingDownload = false;
        state.invoiceDownload = "";
        state.errorDownload = true;
        state.successDownload = false;
      })
      .addCase(donwloadInvoice.fulfilled.type, (state) => {
        state.isLoadingDownload = false;
        state.invoiceDownload = "";
        state.successDownload = true;
        state.errorDownload = false;
      })
      .addCase(downloadMemo.pending.type, (state) => {
        state.isLoadingDownloadMemo = true;
        state.hasErrorMemoDownload = false;
      })
      .addCase(downloadMemo.rejected.type, (state) => {
        state.isLoadingDownloadMemo = false;
        state.hasErrorMemoDownload = true;
      })
      .addCase(downloadMemo.fulfilled.type, (state) => {
        state.isLoadingDownloadMemo = false;
      })
      .addCase(getTotalDue.pending.type, (state) => {
        state.isLoadingTotalDue = true;
        state.totalDue = null;
      })
      .addCase(
        getTotalDue.fulfilled.type,
        (state, action: PayloadAction<TotalDue>) => {
          state.isLoadingTotalDue = false;
          state.totalDue = action.payload;
        }
      )
      .addCase(getTotalDue.rejected.type, (state) => {
        state.isLoadingTotalDue = false;
      })
      .addCase(getInvoiceComponents.pending.type, (state) => {
        state.isLoadingInvoiceComponents = true;
      })
      .addCase(
        getInvoiceComponents.fulfilled.type,
        (state, action: PayloadAction<DocumentComponents>) => {
          state.isLoadingInvoiceComponents = false;
          state.documentComponents = action.payload;
        }
      )
      .addCase(getInvoiceComponents.rejected.type, (state) => {
        state.isLoadingInvoiceComponents = false;
      })
      .addCase(getMemoComponents.pending.type, (state) => {
        state.isLoadingInvoiceComponents = true;
        state.documentComponents = null;
      })
      .addCase(
        getMemoComponents.fulfilled.type,
        (state, action: PayloadAction<DocumentComponents>) => {
          state.isLoadingInvoiceComponents = false;
          state.documentComponents = action.payload;
        }
      )
      .addCase(getMemoComponents.rejected.type, (state) => {
        state.isLoadingInvoiceComponents = false;
      })
      .addCase(getServiceAccountRelationships.pending.type, (state) => {
        state.isLoadingServiceAccounts = true;
        state.serviceAccounts = null;
      })
      .addCase(
        getServiceAccountRelationships.fulfilled.type,
        (
          state,
          action: PayloadAction<EnerbitPagination<ServiceAccountRelationship>>
        ) => {
          state.isLoadingServiceAccounts = false;
          state.serviceAccounts = action.payload;
        }
      )
      .addCase(getServiceAccountRelationships.rejected.type, (state) => {
        state.isLoadingServiceAccounts = false;
      })
      .addCase(uploadPaymentReceipt.pending.type, (state) => {
        state.isUploadingFile = false;
      })
      .addCase(
        uploadPaymentReceipt.fulfilled.type,
        (state, action: PayloadAction<FileResponse>) => {
          state.transactionError = null;
          state.isUploadingFile = false;
          state.fileResponse = action.payload;
        }
      )
      .addCase(
        uploadPaymentReceipt.rejected.type,
        (state, action: PayloadAction<AxiosError>) => {
          state.isUploadingFile = false;

          if (action.payload?.response?.status === 406) {
            state.transactionError =
              "No tienes permiso para realizar esta acción";
          } else {
            state.transactionError =
              "Ha ocurrido un error al cargar el archivo";
          }
        }
      )
      .addCase(createTransaction.pending.type, (state) => {
        state.isApplyingPayments = true;
        state.transactionError = null;
        state.isTransactionCreated = false;
      })
      .addCase(createTransaction.fulfilled.type, (state) => {
        state.isApplyingPayments = false;
        state.fileResponse = null;
        state.transactionError = null;
        const documentsSelected = state.documentsGroupedUnPayed.document_groups
          .filter((group) => group.isSelected)
          .map((group) => {
            return {
              ...group,
              isSelected: false,
              documents: group.documents.map((document) => ({
                ...document,
                payment_date: moment().toDate(),
              })),
            };
          });
        const documentsUnSelected =
          state.documentsGroupedUnPayed.document_groups.filter(
            (group) => !group.isSelected
          );
        state.documentsGroupedUnPayed = {
          ...state.documentsGroupedUnPayed,
          document_groups: documentsUnSelected,
        };
        state.documentsGroupedPayed = {
          ...state.documentsGroupedPayed,
          document_groups: [
            ...state.documentsGroupedPayed.document_groups,
            ...documentsSelected,
          ],
        };
        if (state.totalDue) {
          state.totalDue = {
            ...state.totalDue,
            total_due:
              state.totalDue.total_due -
              documentsSelected.reduce(
                (previous, current) => previous + current.payable_amount,
                0
              ),
          };
        }
        state.isTransactionCreated = true;
      })
      .addCase(
        createTransaction.rejected.type,
        (state, action: PayloadAction<AxiosError>) => {
          const errorCodes: { [key: number]: string } = {
            400: "Existen múltiples métodos de pago con el mismo código",
            404: "No se encontró el método de pago o el tipo de factura",
            501: "No se pudo procesar el tipo de factura",
            500: "Ha ocurrido un error al crear la transacción",
          };

          const code = action.payload?.response?.status;
          state.transactionError = errorCodes[code ?? 500];

          state.isApplyingPayments = false;
          state.isTransactionCreated = false;
        }
      )
      .addCase(getUserById.pending.type, (state) => {
        state.isLoadingUser = true;
        state.appUser = null;
      })
      .addCase(getUserById.rejected.type, (state) => {
        state.isLoadingUser = false;
      })
      .addCase(
        getUserById.fulfilled.type,
        (state, action: PayloadAction<AppUser>) => {
          state.isLoadingUser = false;
          state.appUser = action.payload;
        }
      );
  },
});

export const {
  setResetVars,
  onDocumentGroupCheckChanged,
  onAllUnPayedGroupsCheckChanged,
  onDocumentGroupExpandedChanged,
  onDocumentSelectedChanged,
  onComponentsSelectedCleaned,
  onFiltersChanged,
  cleanTransactionMessage,
  resetTransaction,
  onCleanUnPayedGroups,
  onCleanPayedGroups,
  onFiltersCleaned,
  onCleanServiceAccounts,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
