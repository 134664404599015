import { getMagicalParamUrl, api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetServiceAccountsRelationships } from "../slices/serviceAccountSlice";

export const getServiceAccountsRelationships = createAsyncThunk(
  "serviceAccounts/getServiceAccountsRelationshipsByServiceAccount",
  async ({ page, size }: GetServiceAccountsRelationships) => {
    let param = getMagicalParamUrl();
    const res = await api.get("/accounts/service-accounts-relationships/", {
      params: {
        page: page,
        size: size,
        user_id: param,
      },
    });
    return res.data;
  }
);