import { ServicesAccount } from "../models/states/ServiceAccountState";

export const getAliasServiceGroup = (value?: ServicesAccount) => {
  if (value)
    return value.personalizations.length > 0 ? value.personalizations[0].alias : value.service_account_id.substr(0, 8);
};

export const getParamsUrl = (param: string): string => {
  const url = new URL(window.location.href.toString().replace("/#", ""));
  const queryParams = new URLSearchParams(url.search);
  const response = queryParams.get(param);
  return response || "";
};
