import { enerbitColors } from "@enerbit/base";
import { BillingStatus } from "../../../models/enums/BillingStatus";

interface BillingStatusChipProps {
  type: BillingStatus;
}

interface ChipStyle {
  borderColor?: string;
  backgroundColor?: string;
  color?: string;
}

const mapStatusToStyle = (type: BillingStatus): ChipStyle => {
  switch (type) {
    case BillingStatus.CreditNote:
      let statusColor = enerbitColors.success[600];
      return { color: statusColor, borderColor: statusColor };
    case BillingStatus.Paid:
      return {
        color: enerbitColors.success[600],
        borderColor: enerbitColors.success[400] ?? "green",
      };
    case BillingStatus.DebitNote:
      let errorColor = enerbitColors.success[600];
      return { color: errorColor, borderColor: errorColor };
    case BillingStatus.Pending:
      const warningColor = enerbitColors.warning[100] ?? "#FFFAEB";
      return {
        color: enerbitColors.warning.main,
        borderColor: warningColor,
        backgroundColor: warningColor,
      };
    case BillingStatus.Overdue:
      return {
        color: enerbitColors.error[600],
        borderColor: enerbitColors.error[100],
        backgroundColor: enerbitColors.error[100],
      };
    case BillingStatus.InvoiceWithMemos:
      return {
        color: enerbitColors.warning[600],
        borderColor: enerbitColors.warning[600],
      };
  }
};

const BillingStatusChip = ({ type }: BillingStatusChipProps) => {
  const style = mapStatusToStyle(type);
  return (
    <div
      className="billing-status-chip"
      style={{ marginRight: "5px", ...style }}
    >
      {type.toString()}
    </div>
  );
};

export default BillingStatusChip;
