import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  EBillingInvoiceStatus,
  EBillingMemoStatus,
  ITotalDue,
} from "../../models/interfaces/IBilling";
import { api } from "@enerbit/base";
import { AxiosInstance } from "axios";
import { ITotalOweByEssId } from "../../models/billing/billing";

export const getTotalDue = createAsyncThunk(
  "invoices/getTotalDue",
  async (
    {
      service_account_id,
      user_id,
    }: { service_account_id: string; user_id: string },
    thunkAPI
  ): Promise<ITotalDue | any> => {
    try {
      const { data } = await (api as AxiosInstance).get(`/billing/total-due`, {
        params: {
          service_account_id,
          user_id,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getTotalOwedByEssId = createAsyncThunk(
  "invoices/getTotalOwed",
  async (
    { ess_id, month_filter }: { ess_id: string; month_filter: string },
    thunkAPI
  ): Promise<ITotalOweByEssId | any> => {
    try {
      const { data } = await (api as AxiosInstance).get(
        `/electricity-supply-service/billing/billing/total_owe/${ess_id}`,
        {
          params: {
            month_filter: month_filter,
          },
        }
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

interface DownloadDocumentsMergeProps {
  userId: string;
  service_accounts_data?: {
    service_account_id: string;
    invoice_periods: string[];
  }[];
  service_id?: string;
  document_ids?: {
    document_id: string;
    document_type: string;
  }[];
}

export const downloadDocumentMerge = createAsyncThunk(
  "invoices/downloadDocumentsMerge",
  async (
    {
      userId,
      service_accounts_data = [],
      service_id,
      document_ids,
    }: DownloadDocumentsMergeProps,
    _thunkAPI: any
  ) => {
    let path = `/billing/v2/invoice-documents-merge/${userId}`;
    let response = await api.post(
      path,
      { service_accounts_data, service_id, document_ids },
      { responseType: "arraybuffer" }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `${new Date().getTime()}.pdf`;
    link.click();
    URL.revokeObjectURL(fileURL);
  }
);

export type GetUserBillingStatusProps = {
  user_id?: string;
  service_id?: string | null;
  service_account_ids: string[];
  document_components: EBillingMemoStatus | null;
  document_state: EBillingInvoiceStatus | null;
  document_period_start: string;
  document_period_end: string | null;
  page?: number;
  size?: number;
};

export const getUserBillingStatus = createAsyncThunk(
  "invoices/user-billing-status/{user_id}",
  async ({
    user_id,
    service_account_ids,
    document_components = null,
    document_state = null,
    document_period_start,
    document_period_end = null,
    page = 0,
    size = 15,
    service_id,
  }: GetUserBillingStatusProps) => {
    const params = {
      service_account_ids:
        service_account_ids.length > 0
          ? service_account_ids.join(",")
          : undefined,
      document_period_start,
      document_period_end: document_period_end ?? null,
      document_components:
        document_components != null ? document_components.toString() : null,
      document_state: document_state != null ? document_state.toString() : null,
      user_id,
      page,
      size,
      service_id,
    };

    const res = await api.get(`/billing/user-billing-status/`, {
      params,
    });

    return res.data;
  }
);

export type GetDocumentsHistoryProps = {
  user_id: string;
  service_id?: string;
  service_account_id: string;
  document_period: string;
  is_paid: boolean | null;
  page: number | null;
  size?: number | null;
};

export const getDocumentsHistory = createAsyncThunk(
  "invoices/documents-history/{user_id}",
  async ({
    user_id,
    service_account_id,
    document_period,
    is_paid = null,
    page = 0,
    size = 5,
    service_id,
  }: GetDocumentsHistoryProps) => {
    const res = await api.get(`/billing/documents-history/${user_id}`, {
      params: {
        service_account_id,
        document_periods: document_period,
        is_paid,
        page,
        size,
        service_id,
      },
    });

    return {
      document_period,
      service_account_id,
      ...res.data,
    };
  }
);
